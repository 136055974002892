import React from "react"

import { graphql } from "gatsby"

import Container from "components/container"
import Hero from "components/hero"
import Layout from "components/layout"
import SeparatorH2Style from "components/separator-h2-style"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default ({ data }) => (
  <Layout
    title="Media"
    description="Get the latest news for Ember and media contacts"
  >
    <Hero title="Press" />
    <Container size="medium">
      <SeparatorH2Style>
        <h2>Press Releases</h2>
        <p>
          <a href="/press/2020/ember-launch">
            1st October 2020: Ember launches UK’s first all-electric intercity
            bus service
          </a>
        </p>
        <h2>Photos and Collateral</h2>
        <p>
          You are free to use{" "}
          <a href="https://photos.app.goo.gl/dRZxiu5ALtabhghy9" target="_blank">
            our press photos
          </a>{" "}
          showing the bus. All credits should be to Ember.
        </p>
        <h2>Media Inquiries</h2>
        <p>
          Send an email to <a href="mailto:media@ember.to">media@ember.to</a> if
          you are interested in finding out more or setting up an interview.
        </p>
      </SeparatorH2Style>
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
